import React from 'react'
import Sidebar from '../components/Sidebar'
import Main from '../components/Main'

export default function HomeScreen() {
  return (
    <div id='home-screen' className='flex'>
      <Sidebar />
      <Main />
    </div>
  )
}