// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMdjw7VnQ7KwBfqZbfcJDkNprApp23EoU",
  authDomain: "layoutsnblocks.firebaseapp.com",
  projectId: "layoutsnblocks",
  storageBucket: "layoutsnblocks.appspot.com",
  messagingSenderId: "191520117268",
  appId: "1:191520117268:web:a517750c0c7554decf4d01"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)