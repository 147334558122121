import React from 'react'

export default function PopUp(props) {
  
  return (
    <div datatype-popup={props.active} className='popup | bg-accent-400 clr-primary-800 pg-3 '>
        <h1>{props.popup}</h1>
    </div>
  )
}

