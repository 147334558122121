export function Menu(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M4.5 17.27q-.213 0-.356-.145Q4 16.981 4 16.77q0-.213.144-.356q.143-.144.356-.144h15q.213 0 .356.144t.144.357q0 .213-.144.356t-.356.143zm0-4.77q-.213 0-.356-.144Q4 12.212 4 12t.144-.356q.144-.143.356-.143h15q.213 0 .356.144q.144.144.144.357t-.144.356q-.143.143-.356.143zm0-4.77q-.213 0-.356-.143T4 7.23q0-.213.144-.356q.143-.143.356-.143h15q.213 0 .356.144q.144.144.144.356q0 .213-.144.357q-.144.143-.356.143z"></path></svg>
    )
}

export function Close(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="m12 12.708l-5.246 5.246q-.14.14-.344.15q-.204.01-.364-.15t-.16-.354q0-.194.16-.354L11.292 12L6.046 6.754q-.14-.14-.15-.344q-.01-.204.15-.364t.354-.16q.194 0 .354.16L12 11.292l5.246-5.246q.14-.14.344-.15q.204-.01.364.15t.16.354q0 .194-.16.354L12.708 12l5.246 5.246q.14.14.15.344q.01.204-.15.364t-.354.16q-.194 0-.354-.16z"></path></svg>
  )
}

export function Copyright(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="#f4f8ff" d="M10.08 10.86c.05-.33.16-.62.3-.87s.34-.46.59-.62c.24-.15.54-.22.91-.23c.23.01.44.05.63.13c.2.09.38.21.52.36s.25.33.34.53s.13.42.14.64h1.79c-.02-.47-.11-.9-.28-1.29s-.4-.73-.7-1.01s-.66-.5-1.08-.66s-.88-.23-1.39-.23c-.65 0-1.22.11-1.7.34s-.88.53-1.2.92s-.56.84-.71 1.36S8 11.29 8 11.87v.27c0 .58.08 1.12.23 1.64s.39.97.71 1.35s.72.69 1.2.91c.48.22 1.05.34 1.7.34c.47 0 .91-.08 1.32-.23s.77-.36 1.08-.63s.56-.58.74-.94s.29-.74.3-1.15h-1.79c-.01.21-.06.4-.15.58s-.21.33-.36.46s-.32.23-.52.3c-.19.07-.39.09-.6.1c-.36-.01-.66-.08-.89-.23c-.25-.16-.45-.37-.59-.62s-.25-.55-.3-.88s-.08-.67-.08-1v-.27c0-.35.03-.68.08-1.01M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8"></path></svg>
  )
}
export function ArrowDown(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" {...props} fill="currentColor"> <path d="M6.42833 7.35458L9.5 10.4262L12.5717 7.35458C12.645 7.28129 12.732 7.22315 12.8277 7.18348C12.9235 7.14381 13.0261 7.1234 13.1298 7.1234C13.2334 7.1234 13.3361 7.14381 13.4318 7.18348C13.5276 7.22315 13.6146 7.28129 13.6879 7.35458C13.7612 7.42788 13.8193 7.51489 13.859 7.61065C13.8987 7.70641 13.9191 7.80905 13.9191 7.91271C13.9191 8.01636 13.8987 8.119 13.859 8.21476C13.8193 8.31052 13.7612 8.39754 13.6879 8.47083L10.0542 12.1046C9.74541 12.4133 9.24666 12.4133 8.93791 12.1046L5.30416 8.47083C5.23077 8.39759 5.17255 8.3106 5.13282 8.21483C5.0931 8.11905 5.07265 8.01639 5.07265 7.91271C5.07265 7.80902 5.0931 7.70636 5.13282 7.61059C5.17255 7.51482 5.23077 7.42782 5.30416 7.35458C5.61292 7.05375 6.11958 7.04583 6.42833 7.35458Z" fill="#151D32"/> </svg>  
  )
}
export function Layouts(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props} fill="currentColor"> <path opacity="0.2" d="M13 13V26H5C4.73478 26 4.48043 25.8946 4.29289 25.7071C4.10536 25.5196 4 25.2652 4 25V13H13Z" fill="#151D32"/> <path d="M27 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V25C3 25.5304 3.21071 26.0391 3.58579 26.4142C3.96086 26.7893 4.46957 27 5 27H27C27.5304 27 28.0391 26.7893 28.4142 26.4142C28.7893 26.0391 29 25.5304 29 25V7C29 6.46957 28.7893 5.96086 28.4142 5.58579C28.0391 5.21071 27.5304 5 27 5ZM27 7V12H5V7H27ZM5 14H12V25H5V14ZM27 25H14V14H27V25Z" fill="#00668C"/> </svg>  
  )
}
export function Components(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <path d="M4 16L8 20L12 16L8 12L4 16ZM20 16L24 20L28 16L24 12L20 16ZM12 8L16 12L20 8L16 4L12 8ZM12 24L16 28L20 24L16 20L12 24Z" stroke="#00668C" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/> </svg>
  )
}
export function Phone(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props} fill="currentColor"> <g clipPath="url(#clip0_9_90)"> <path d="M22 2C22.5304 2 23.0391 2.21071 23.4142 2.58579C23.7893 2.96086 24 3.46957 24 4V28C24 28.5304 23.7893 29.0391 23.4142 29.4142C23.0391 29.7893 22.5304 30 22 30H10C9.46957 30 8.96086 29.7893 8.58579 29.4142C8.21071 29.0391 8 28.5304 8 28V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H22ZM10 0C8.93913 0 7.92172 0.421427 7.17157 1.17157C6.42143 1.92172 6 2.93913 6 4V28C6 29.0609 6.42143 30.0783 7.17157 30.8284C7.92172 31.5786 8.93913 32 10 32H22C23.0609 32 24.0783 31.5786 24.8284 30.8284C25.5786 30.0783 26 29.0609 26 28V4C26 2.93913 25.5786 1.92172 24.8284 1.17157C24.0783 0.421427 23.0609 0 22 0L10 0Z" fill="currentColor"/> <path d="M16 28C16.5304 28 17.0391 27.7893 17.4142 27.4142C17.7893 27.0391 18 26.5304 18 26C18 25.4696 17.7893 24.9609 17.4142 24.5858C17.0391 24.2107 16.5304 24 16 24C15.4696 24 14.9609 24.2107 14.5858 24.5858C14.2107 24.9609 14 25.4696 14 26C14 26.5304 14.2107 27.0391 14.5858 27.4142C14.9609 27.7893 15.4696 28 16 28Z" fill="currentColor"/> </g> <defs> <clipPath id="clip0_9_90"> <rect width="32" height="32" fill="white"/> </clipPath> </defs> </svg>  
  )
}
export function Tablet(props) {
  return (
    <svg width="37" height="32" viewBox="0 0 37 32"  {...props} fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_209_7)"> <path d="M27.75 2C28.3633 2 28.9515 2.21071 29.3852 2.58579C29.8189 2.96086 30.0625 3.46957 30.0625 4V28C30.0625 28.5304 29.8189 29.0391 29.3852 29.4142C28.9515 29.7893 28.3633 30 27.75 30H9.25C8.63669 30 8.04849 29.7893 7.61482 29.4142C7.18114 29.0391 6.9375 28.5304 6.9375 28V4C6.9375 3.46957 7.18114 2.96086 7.61482 2.58579C8.04849 2.21071 8.63669 2 9.25 2H27.75ZM9.25 0C8.02337 0 6.84699 0.421427 5.97963 1.17157C5.11228 1.92172 4.625 2.93913 4.625 4V28C4.625 29.0609 5.11228 30.0783 5.97963 30.8284C6.84699 31.5786 8.02337 32 9.25 32H27.75C28.9766 32 30.153 31.5786 31.0204 30.8284C31.8877 30.0783 32.375 29.0609 32.375 28V4C32.375 2.93913 31.8877 1.92172 31.0204 1.17157C30.153 0.421427 28.9766 0 27.75 0L9.25 0Z" fill="currentColor"/> <path d="M18.5 28C19.1133 28 19.7015 27.7893 20.1352 27.4142C20.5689 27.0391 20.8125 26.5304 20.8125 26C20.8125 25.4696 20.5689 24.9609 20.1352 24.5858C19.7015 24.2107 19.1133 24 18.5 24C17.8867 24 17.2985 24.2107 16.8648 24.5858C16.4311 24.9609 16.1875 25.4696 16.1875 26C16.1875 26.5304 16.4311 27.0391 16.8648 27.4142C17.2985 27.7893 17.8867 28 18.5 28Z" fill="currentColor"/> </g> <defs> <clipPath id="clip0_209_7"> <rect width="37" height="32" fill="white"/> </clipPath> </defs> </svg>
  )
}
export function Computer(props) {
  return (
    <svg width="43" height="31" viewBox="0 0 43 31" {...props} fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M1.63457 30.7349C1.34329 30.7349 1.09965 30.6369 0.903652 30.4409C0.709014 30.2463 0.611694 30.004 0.611694 29.7141C0.611694 29.4228 0.709014 29.1798 0.903652 28.9852C1.09965 28.7906 1.34261 28.6933 1.63253 28.6933H41.3674C41.6574 28.6933 41.8996 28.7906 42.0943 28.9852C42.2903 29.1798 42.3883 29.4228 42.3883 29.7141C42.3883 30.004 42.2903 30.2463 42.0943 30.4409C41.8983 30.6356 41.656 30.7336 41.3674 30.7349H1.63457ZM6.42228 26.6516C5.48311 26.6516 4.69911 26.3365 4.07028 25.7063C3.44008 25.0775 3.12499 24.2928 3.12499 23.3523V3.40517C3.12499 2.466 3.44008 1.682 4.07028 1.05317C4.70047 0.424333 5.48447 0.109917 6.42228 0.109917H36.5777C37.5169 0.109917 38.3009 0.424333 38.9297 1.05317C39.5599 1.68336 39.875 2.46804 39.875 3.40721V23.3543C39.875 24.2935 39.5606 25.0775 38.9317 25.7063C38.3029 26.3351 37.5182 26.6502 36.5777 26.6516H6.42228ZM6.42228 24.6099H36.5777C36.8908 24.6099 37.1786 24.4786 37.4413 24.2159C37.7027 23.9545 37.8333 23.6667 37.8333 23.3523V3.40517C37.8333 3.09211 37.7027 2.80492 37.4413 2.54358C37.1786 2.28225 36.8908 2.15158 36.5777 2.15158H6.42228C6.10922 2.15158 5.82135 2.28225 5.55865 2.54358C5.29732 2.80492 5.16665 3.09279 5.16665 3.40721V23.3543C5.16665 23.6673 5.29732 23.9545 5.55865 24.2159C5.82135 24.4786 6.10922 24.6099 6.42228 24.6099Z" fill="currentColor"/> </svg>
  )
}
export function Premium(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props} fill="currentColor"> <path d="M8 2.66666H10.7893L7.99199 10.6667H3.17599L6.80799 3.40266C6.91883 3.18147 7.08903 2.99548 7.29954 2.8655C7.51006 2.73551 7.75259 2.66667 8 2.66666ZM3.27199 13.3333L11.3093 25.832L7.92 13.3333H3.27199ZM10.6827 13.3333L14.7627 28.3867C14.835 28.6597 14.9955 28.9011 15.2193 29.0733C15.4431 29.2455 15.7176 29.3389 16 29.3389C16.2824 29.3389 16.5569 29.2455 16.7807 29.0733C17.0045 28.9011 17.165 28.6597 17.2373 28.3867L21.328 13.3333H10.6827ZM24.0907 13.3333L20.6933 25.8267L28.728 13.3333H24.0907ZM28.824 10.6667H24.016L21.216 2.66666H24C24.2479 2.66617 24.4909 2.73478 24.702 2.86479C24.913 2.9948 25.0836 3.18106 25.1947 3.40266L28.824 10.6667ZM21.192 10.6667H10.8187L13.6133 2.66666H18.3867L21.192 10.6667Z" fill="#00668C"/> </svg>
  )
}
export function Logout(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <path fillRule="evenodd" clipRule="evenodd" d="M22.04 7.54V10.66L24.04 8.68V5L23.04 4H5.04L4.04 5V5.972L4 6V26.58L4.72 27.5L14.72 30.94L16 30V28H23.04L24.04 27V23.38L22.04 21.38V26H16V9.42L15.34 8.5L8.072 6H22.04V7.54ZM14 28.56L6 25.88V7.44L14 10.12V28.56ZM27.04 16.96H17.1V14.96H26.96L23.76 11.76L25.18 10.36L30.12 15.28V16.7L25.14 21.66L23.74 20.26L27.04 16.96Z" fill="#00668C"/> </svg>  
  )
}

export function ColorPalette(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}><circle cx="10" cy="12" r="2" fill="currentColor"></circle><circle cx="16" cy="9" r="2" fill="currentColor"></circle><circle cx="22" cy="12" r="2" fill="currentColor"></circle><circle cx="23" cy="18" r="2" fill="currentColor"></circle><circle cx="19" cy="23" r="2" fill="currentColor"></circle><path fill="currentColor" d="M16.54 2A14 14 0 0 0 2 16a4.82 4.82 0 0 0 6.09 4.65l1.12-.31a3 3 0 0 1 3.79 2.9V27a3 3 0 0 0 3 3a14 14 0 0 0 14-14.54A14.05 14.05 0 0 0 16.54 2m8.11 22.31A11.93 11.93 0 0 1 16 28a1 1 0 0 1-1-1v-3.76a5 5 0 0 0-5-5a5.07 5.07 0 0 0-1.33.18l-1.12.31A2.82 2.82 0 0 1 4 16A12 12 0 0 1 16.47 4A12.18 12.18 0 0 1 28 15.53a11.89 11.89 0 0 1-3.35 8.79Z"></path></svg>
  )
}

export function DoubleArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M11.05 12L7.175 6.575q-.35-.5-.087-1.037T7.975 5q.25 0 .475.113t.35.312L13.5 12l-4.7 6.575q-.125.2-.35.313T7.975 19q-.6 0-.875-.537t.075-1.038zM17 12l-3.875-5.425q-.35-.5-.088-1.037T13.926 5q.25 0 .475.113t.35.312L19.45 12l-4.7 6.575q-.125.2-.35.313t-.475.112q-.6 0-.875-.537t.075-1.038z"></path></svg>
  )
}

export function Code(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="#1d1c1c" d="m8 18l-6-6l6-6l1.425 1.425l-4.6 4.6L9.4 16.6zm8 0l-1.425-1.425l4.6-4.6L14.6 7.4L16 6l6 6z"></path></svg>
  )
}

export function LinkedIn(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}><path fill="currentColor" d="M216 24H40a16 16 0 0 0-16 16v176a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16m0 192H40V40h176zM96 112v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0m88 28v36a8 8 0 0 1-16 0v-36a20 20 0 0 0-40 0v36a8 8 0 0 1-16 0v-64a8 8 0 0 1 15.79-1.78A36 36 0 0 1 184 140m-84-56a12 12 0 1 1-12-12a12 12 0 0 1 12 12"></path></svg>
  )
}

export function Instagram(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}><path fill="currentColor" d="M128 80a48 48 0 1 0 48 48a48.05 48.05 0 0 0-48-48m0 80a32 32 0 1 1 32-32a32 32 0 0 1-32 32m48-136H80a56.06 56.06 0 0 0-56 56v96a56.06 56.06 0 0 0 56 56h96a56.06 56.06 0 0 0 56-56V80a56.06 56.06 0 0 0-56-56m40 152a40 40 0 0 1-40 40H80a40 40 0 0 1-40-40V80a40 40 0 0 1 40-40h96a40 40 0 0 1 40 40ZM192 76a12 12 0 1 1-12-12a12 12 0 0 1 12 12"></path></svg>
  )
}

export function Facebook(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14" {...props}><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M.5 12.5v-11a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-3V8.76h.71a.61.61 0 0 0 .61-.61v-.77a.61.61 0 0 0-.61-.61h-.67v-.94c0-.84.38-.84.76-.84h.49a.55.55 0 0 0 .43-.18a.58.58 0 0 0 .18-.43v-.74a.62.62 0 0 0-.6-.64H9.65a2.32 2.32 0 0 0-2.39 2.6v1.17h-.64a.61.61 0 0 0-.62.61v.77a.61.61 0 0 0 .62.61h.64v4.74H1.5a1 1 0 0 1-1-1Z"></path></svg>
  )
}

export function Figma(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0.67em" height="1em" viewBox="0 0 256 384" {...props}><path fill="#0ACF83" d="M64 384c35.328 0 64-28.672 64-64v-64H64c-35.328 0-64 28.672-64 64s28.672 64 64 64"></path><path fill="#A259FF" d="M0 192c0-35.328 28.672-64 64-64h64v128H64c-35.328 0-64-28.672-64-64"></path><path fill="#F24E1E" d="M0 64C0 28.672 28.672 0 64 0h64v128H64C28.672 128 0 99.328 0 64"></path><path fill="#FF7262" d="M128 0h64c35.328 0 64 28.672 64 64s-28.672 64-64 64h-64z"></path><path fill="#1ABCFE" d="M256 192c0 35.328-28.672 64-64 64s-64-28.672-64-64s28.672-64 64-64s64 28.672 64 64"></path></svg>
  )
}

export function Copy(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M9 18q-.825 0-1.412-.587T7 16V4q0-.825.588-1.412T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.587 1.413T18 18zm0-2h9V4H9zm-4 6q-.825 0-1.412-.587T3 20V6h2v14h11v2zm4-6V4z"></path></svg>
  )
}

export function Star(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="m7.625 6.4l2.8-3.625q.3-.4.713-.587T12 2t.863.188t.712.587l2.8 3.625l4.25 1.425q.65.2 1.025.738t.375 1.187q0 .3-.088.6t-.287.575l-2.75 3.9l.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25q-.125.05-.275.063T6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125l-2.725-3.875q-.2-.275-.288-.575T2 9.75q0-.625.363-1.162t1.012-.763zM8.85 8.125L4 9.725L7.1 14.2L7 18.975l5-1.375l5 1.4l-.1-4.8L20 9.775l-4.85-1.65L12 4zM12 11.5"></path></svg>
  )
}

export function FillStar(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="m7.625 6.4l2.8-3.625q.3-.4.713-.587T12 2t.863.188t.712.587l2.8 3.625l4.25 1.425q.65.2 1.025.738t.375 1.187q0 .3-.088.6t-.287.575l-2.75 3.9l.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25q-.125.05-.275.063T6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125l-2.725-3.875q-.2-.275-.288-.575T2 9.75q0-.625.363-1.162t1.012-.763z"></path></svg>
  )
}

export function Profile(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15t3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4T6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5t1.013-2.488T12 6t2.488 1.013T15.5 9.5t-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"></path></svg>
  )
}

export function MyLayouts(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M12 16q1.2 0 2.138-.712T15.5 13.45h-7q.425 1.125 1.363 1.838T12 16m-2.5-4q.625 0 1.063-.437T11 10.5t-.437-1.062T9.5 9t-1.062.438T8 10.5t.438 1.063T9.5 12m5 0q.625 0 1.063-.437T16 10.5t-.437-1.062T14.5 9t-1.062.438T13 10.5t.438 1.063T14.5 12M7.625 6.4l2.8-3.625q.3-.4.713-.587T12 2t.863.188t.712.587l2.8 3.625l4.25 1.425q.65.2 1.025.738t.375 1.187q0 .3-.088.6t-.287.575l-2.75 3.9l.1 4.1q.025.875-.575 1.475t-1.4.6q-.05 0-.55-.075L12 19.675l-4.475 1.25q-.125.05-.275.063T6.975 21q-.8 0-1.4-.6T5 18.925l.1-4.125l-2.725-3.875q-.2-.275-.288-.575T2 9.75q0-.625.363-1.162t1.012-.763zM8.85 8.125L4 9.725L7.1 14.2L7 18.975l5-1.375l5 1.4l-.1-4.8L20 9.775l-4.85-1.65L12 4zM12 11.5"></path></svg>
  )
}

export function RightArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}><path fill="currentColor" d="m220.24 132.24l-72 72a6 6 0 0 1-8.48-8.48L201.51 134H40a6 6 0 0 1 0-12h161.51l-61.75-61.76a6 6 0 0 1 8.48-8.48l72 72a6 6 0 0 1 0 8.48"></path></svg>
  )
}

export function Responsive(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M3 19v-9q0-.825.588-1.412T5 8h3V5q0-.825.588-1.412T10 3h9q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21H5q-.825 0-1.412-.587T3 19m13 0h3V5h-9v3h4q.825 0 1.413.588T16 10zm-6 0h4v-9h-4zm-5 0h3v-9H5z"></path></svg>
  )
}

export function Unlock(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" fillRule="evenodd" d="M10.533 3.811A3.2 3.2 0 0 0 7.83 7.46l.03.203c.04.253.106.5.199.738l.915 2.342a44.612 44.612 0 0 1 6.574.039l1.496.12a2.111 2.111 0 0 1 1.915 1.763a20.64 20.64 0 0 1 0 6.67a2.111 2.111 0 0 1-1.915 1.764l-1.496.12a44.613 44.613 0 0 1-7.098 0l-1.496-.12a2.111 2.111 0 0 1-1.915-1.764a20.641 20.641 0 0 1 0-6.67A2.111 2.111 0 0 1 6.955 10.9l.457-.036l-.75-1.918A4.96 4.96 0 0 1 6.38 7.89l-.031-.204a4.7 4.7 0 0 1 9.024-2.418l.075.193c.133.34.228.695.283 1.057l.141.928a.5.5 0 0 1-.419.57l-.494.075a.5.5 0 0 1-.57-.419l-.14-.928a3.459 3.459 0 0 0-.198-.738l-.075-.192a3.2 3.2 0 0 0-3.442-2.002M12 14.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3" clipRule="evenodd"></path></svg>
  )
}


export function Home(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M7 20q-.825 0-1.412-.587T5 18v-7.15l-2 1.525q-.35.25-.75.213T1.6 12.2t-.2-.75t.4-.65l8.975-6.875q.275-.2.588-.3t.637-.1t.638.1t.587.3L16 6.05V5.5q0-.625.438-1.062T17.5 4t1.063.438T19 5.5v2.85l3.2 2.45q.325.25.388.65t-.188.75t-.65.388t-.75-.213l-2-1.525V18q0 .825-.587 1.413T17 20h-2q-.825 0-1.412-.587T13 18v-4h-2v4q0 .825-.587 1.413T9 20zm0-2h2v-4q0-.825.588-1.412T11 12h2q.825 0 1.413.588T15 14v4h2V9.325l-5-3.8l-5 3.8zm3-7.975h4q0-.8-.6-1.313T12 8.2t-1.4.513t-.6 1.312M9 18v-4q0-.825.588-1.412T11 12h2q.825 0 1.413.588T15 14v4v-4q0-.825-.587-1.412T13 12h-2q-.825 0-1.412.588T9 14z"></path></svg>
  )
}