import React, { useEffect, useRef } from 'react'
import { Close } from '../../images/icons/Icons'

export default function PrimaryPopup(props) {
    let modalRef = useRef()
    useEffect(() => {
        if (props.openPopup) {
            modalRef.current.showModal()
            document.body.style.overflowY='hidden'
        }
    }, [props.openPopup])
    
    function closeModal(e) {
        modalRef.current.close()
        props.closePopup(false)
        document.body.style.overflowY='scroll'
    }
    function handleClick(e) {
        if (e.target === modalRef.current) {
            closeModal()
        }
    }
    function handleButtonClick(e) {
        closeModal()
        props.callBack()
    }
  return (
    <dialog className='primary-popup |  position-center text-center' onClick={(e) => { handleClick(e) }} ref={modalRef}>
        <div className='primary-popup-wrapper | flow-4 pg-2'>
            <div className=''>
                <Close className='flex-js-e fs-600 pointer' onClick={(e) => { closeModal(e) }}/>
            </div>
            <h1 className='clr-accent-400'>
                {props.popupPrompt}
            </h1>
            <button className='button' datatype-button='primary' onClick={(e) => { handleButtonClick(e) }}>{props.buttonPrompt}</button>
        </div>
    </dialog>
  )
}
