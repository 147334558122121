import React from 'react'
import Logo from '../images/logo.svg'
import Hero from '../images/hero-img.svg'
import Section1 from '../images/section-img-1.svg'
import SectionCode from '../images/section-code.svg'
import { ArrowDown, Copy, Facebook, Instagram, LinkedIn, Premium, Responsive, RightArrow, Star } from '../images/icons/Icons'
import { Link } from 'react-router-dom'

export default function HomePage() {
  return (
    <main className='bg-primary-200'>
      <header className='header | container flex-jc-sb flex-al-c'>
        <a href='/home' className="logo-wrapper">
          <img src={Logo} alt="" className="logo" />
        </a>
        <ul className="navLinks flex gap-1">
          <li className='flex-al-c'>
            <Link to='/premium' datatype-button='secondary' className='button button-img'>
              <Premium className="fs-100" />
              Unlock Premium
            </Link>
          </li>
          <li className='flex-al-c'>
            <Link to='/' datatype-button='primary' className='button button-img'>
              Get Layouts
              <RightArrow className="fs-400" color='white'/>
            </Link>
          </li>
        </ul>
      </header>

      <section className="hero-wrapper flex-center">
        <div className='hero | even-columns container'>
          <div className="left-col | flow-4">
            <h1 className='hero-heading | clr-accent-400' >Welcome to LayoutsNblocks!</h1>
            <p>The only website tool to get layout templates for your specific website design and development needs.</p>
            <Link to='/' datatype-button='primary' className='button button-img'>Explore our Layouts<RightArrow className="fs-400"/></Link>
          </div>
          <div className="right-col">
            <img src={Hero} alt="" />
          </div>
        </div>
      </section>

      <section className="section bg-accent-800">
        <div className='container responsive-even-columns gap-6'> 
          <img className='section-img' src={Section1} alt="" />
          <p className='section-heading | clr-accent-400'>We have a large selection of different website blocks for any section of your website.</p>
        </div>
      </section>

      <section className="section section-img container responsive-even-columns gap-6">
        <div className="layout-copy left-col flow-4">
          <Copy className='copy | mx-auto fs-700 ' color="#00658a" />
          <p className='section-heading clr-accent-400'>See a layout that you like? Copy and paste that code with just 1 click.</p>
        </div>
        <img className='right-align' src={SectionCode} alt="" />
      </section>

      <section className='premium | section text-center bg-accent-400 clr-primary-200'>
        <div className='premium-wrapper mx-auto flow-6'>
          <h1>To unlock all of our layouts and get access to our extra tools.</h1>
          <Link to='/premium' datatype-button='secondary' className='button button-img mx-auto'>
            <Premium />
            Unlock Premium
          </Link>
        </div>
      </section>

      <section className='featured-section | section clr-accent-400 fs-400 text-center'>
        <div className='featured-wrapper | container'>
          <div>
            <div className='img-cont'>
              <Responsive color='#00658a' />
            </div>
            <p>All layouts are responsive among all screen sizes and can be viewed with our simple responsive tool. </p>
          </div>
          <div>
            <div className='img-cont'>
              <button datatype-button='primary' className='button mx-auto'>Go Here</button>
            </div>
            <p>Components like buttons are customizable, eg: color, border-radius and shadow-box.</p>
          </div>
          <div>
            <div className='img-cont'>
              <Star />
            </div>
            <p>Layouts and components can be saved as favorites for future projects.</p>
          </div>

        </div>
      </section>
      <footer className='section bg-dark-400 clr-primary-800'>
        <div className='container even-columns gap-2'>
          <div className="left-col">
            <img src={Logo} alt="" className="logo mg-block-end-6" />
            <div className='flow-2'>
              <h3>Follow Us</h3>
              <ul className='social-links | flex gap-1'>
                <li><Instagram/></li>
                <li><LinkedIn/></li>
                <li><Facebook/></li>
              </ul>
            </div>
          </div>
          <div className="right-col flow-3">
            <h3>Email Us</h3>
            <textarea className='fs-200' name="" id=""></textarea>
            <button datatype-button='primary' className='button'>Submit</button>
          </div>
        </div>
      </footer>
    </main>
  )
}
