import { Link } from 'react-router-dom';
import axios from '../axiosConfig'

export default function Checkout(props) {

  async function handleCheckout() {
    axios.post(process.env.REACT_APP_API_URL+'/create-checkout-session',{
    customerEmail:props.email}
    ).then((res) => {
      if (res.data.url) {
        window.location.href = res.data.url
      }
    })
  }

  if (props.email) {
      return <button onClick={handleCheckout} className='button' datatype-button='primary'>Get Premium</button>
    }else{
        return <Link state={'/premium'} to='/login' className='button' datatype-button='primary'>Login/Sign Up First</Link>
  }
  ;
}