import React, { useEffect, useRef, useState } from 'react'
import {Figma, Code, Copy, Star, FillStar} from '../images/icons/Icons'
import { CodeBlock, atomOneLight } from "react-code-blocks";
import JsxParser from 'react-jsx-parser';
import styled from 'styled-components';
import PopUp from './PopUp';
import 'firebase/firestore'
import SendData from './Functions/SendData';
import {useNavigate, useParams} from 'react-router-dom'
import Auth from './Functions/Auth';
import PrimaryPopup from './Functions/PrimaryPopup';

const StyledDiv = styled.div`${props => props.$primary}`

export default function CodeContainer(props) {
    const [popup, setPopup] = useState('')
    const [primaryPopup, setPrimaryPopup] = useState(false)
    const [codes, setCodes] = useState(props.component.code)
    const [styles, setStyles] = useState(props.component.style)
    const [favorite, setFavorite] = useState(false)
    const [layouts, setLayouts] = useState(props.favorite)
    const [auth, setAuth] = useState(null)
    const [count, setCount] = useState(0)
    const component = useParams().component
    const navigate = useNavigate()

    const code = props.component.code
    const style = props.component.style
    const test = 'button {'
    const testString = sessionStorage.getItem('button')
    const screenWidth = props.screenWidth
    const primaryPopupPrompt = ''
    
    useEffect(() => {
        if (testString) {
            if (style.search('button') !== -1) {
                let splitString = testString.split(';')
                splitString = splitString.map((x, i) => x.replace('\t', `    `) )
                const joinString = splitString.join(';')
                const newStyle= style.replace(test, test +'\n' + joinString);    
                setStyles(newStyle)
            }
        }
        
    }, [])

    useEffect(() => {
      Auth(setAuth)
      if (layouts?.includes(component)) {
        setFavorite(true)
      }
    }, [auth])
    
    
    const toggleFavorite = (e) => {
        if (!auth) {
            setPrimaryPopup(true)
        }
        let layout = layouts
        if (favorite) {
            setFavorite(false)
            layout = layout.filter(x=>x !== component)
        }else{
            if (auth?.uid) {
                if (layouts?.includes(component)) {
                    layout = layouts
                }else{
                    layout = [...layouts,component]
                }
                setFavorite(true)
            }
        }
        if (layout) {
            sessionStorage.setItem('userLayouts', JSON.stringify(layout))
            console.log(layout);
            SendData('users', auth.uid, {layouts: layout})
        }
    }
    
    
    const copyCode = (e) => { 
        if (e === 0) {
            navigator.clipboard.writeText(codes) 
        }
        if (e === 1) {
            navigator.clipboard.writeText(styles) 
        }
        if (e === 2) {
            navigator.clipboard.writeText(codes + '\n \n' + styles) 
        }
        setPopup('active')
    }
    
    window.onanimationend =()=>{
        setPopup('')
    }
    const handleCodeChange = (e) => {
        const value = e.target.value
        if (value === 'html') {
            setCodes(codes.replaceAll('className', 'class'));
        }
        else if (value === 'react') {
            setCodes(codes.replaceAll('class', 'className'));
        }
    }
    function getPremium() {
        navigate('/login')
    } 
    
      return (
          <section  className='code-container-section flow-8 relative'>
                <div style={{maxWidth: `${screenWidth}px`}} className='dv mx-auto' >
                    <StyledDiv $primary={styles} className='component'>
                        <JsxParser jsx={codes} /> 
                    </StyledDiv>
                </div>
            <div className='bg-primary-400'>
            <ul className="top | flex  bg-primary-400">
                <li className='select-code-wrapper flex-center | pg-inline-2'>
                        <Code />
                    <select onChange={(e) => { handleCodeChange(e) }} className='select-code clr-dark-400' name="" id="">
                        <option value="html">Html and Css</option>
                        <option value="react">React and Css</option>
                    </select>
                </li>
                <li className='flex'>
                    <button className='top_button | gap-1 flex-al-c pointer' onClick={(e) => {copyCode(2)}}>
                        <p><Copy/> Html & Css</p>
                    </button>
                    <button className='top_button | gap-1 flex-al-c pointer' onClick={(e) => {copyCode(0)}}>
                        <p><Copy/> Html</p>
                    </button>
                    <button className='top_button | gap-1 flex-al-c pointer' onClick={(e) => {copyCode(1)}}>
                        <p><Copy/> Css</p>
                    </button>
                </li>
                    <button onClick={(e) => { toggleFavorite()}} className='flex-js-e pointer mg-inline-end-1'>
                        {favorite ? <FillStar color='orange'/> : <Star/>}
                    </button>
            </ul>
            <div className="code-container-wrapper | flow-3 pg-block-6 pg-inline-8">
                <div className="code-container">
                        <CodeBlock
                            text={codes}
                            language='html'
                            showLineNumbers={true}
                            theme={atomOneLight}
                            startingLineNumber={0}
                            codeBlock
                            
                            />
                    </div>
                <div className="code-container">
                    <CodeBlock
                        startingLineNumber={0}
                        text={styles}
                        language='css'
                        showLineNumbers={true}
                        theme={atomOneLight}
                        />
                </div>
            </div>
        </div>
        <PopUp setPopup={setPopup} active={popup} popup={'Code Copied'} />
        <PrimaryPopup 
            callBack={getPremium}
            openPopup={primaryPopup} 
            closePopup={setPrimaryPopup}  
            popupPrompt={"To save layouts you'll first need to create an account or login."} 
            buttonPrompt={'Sign Up / Login'} 
        />
    </section>
  )
}