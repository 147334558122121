import React, { useEffect} from 'react'
import Sidebar from '../../components/Sidebar'
import Main from '../../components/Main'
import { auth } from '../../firebase/firebase'
import { useNavigate } from 'react-router-dom'

export default function AdminScreen() {
  const navigate = useNavigate() 
  useEffect(() => {
    auth.onAuthStateChanged((user)=>{
      if (user) {
        if (!user.displayName === 'admin') {
          navigate('/')
        }
      }else{
        navigate('/')
      }
    })
  }, [auth])

  return (
    <div id='admin-screen' className='flex'>
        <Sidebar />
        <Main />
    </div>
  )
}
