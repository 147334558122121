export const buttons = [
    // {
    //     type: 'fill',
    //     align: `center`
    //     value:''
    // },
    // {
    //     type: 'unfill',
    //     align: `left`
    //     value:''
    // },
    {
        type: 'borderRadius',
        value:'0px',
    },
    {
        type: 'borderRadius',
        value:'10px',
    },
    {
        type: 'borderRadius',
        value:'50vw'
    },
    {
        type: 'boxShadow',
        value:'3px 3px 13.6px 0px rgba(0, 0, 0, 0.4)',
    },
]