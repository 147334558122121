import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Auth from '../components/Functions/Auth'
import SendData from '../components/Functions/SendData'

export default function Success() {
  const [customer, setCustomer] = useState(null)
  const [auth, setAuth] = useState(null)
  useEffect(() => {
    const url = window.location.search
    Axios.get(`http://127.0.0.1:5001/layoutsnblocks/us-central1/app/success${url}`)
    .then((res) => {setCustomer(res.data)})
    .then(() => { Auth((e) => {setAuth(e)} ) })
  }, [])
  useEffect(() => {
    if (auth && customer) {
      if (auth.email === customer.email) {
        const customerPremium = 
        {
          id: customer.id, 
          premium: true,
          created:customer.created,
          invoice: customer.invoice_prefix
        }
        SendData('users',auth.uid,{premium:customerPremium})
      }
    }
  }, [customer, auth])
  
  
  return (
    <main className='hero flex-center section text-center'>
        <div className='flow-8'>
            <h1 className='hero-heading | clr-accent-400'>Thank You for purchasing our Premium plan</h1>
            <p>Now explore all of our new layouts and advanced features</p>
            <div>
                <Link to='/' className='button' datatype-button='primary'>Explore</Link>
            </div>
        </div>
    </main>
  )
}
