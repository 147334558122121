import React, { Component } from 'react'
import {auth} from '../firebase/firebase'
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { Navigate} from 'react-router-dom'

export class Login extends Component {
    state={
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        accessType: 0,
        redirect: false,
        admin: 'Czc4LHmEeRXrRxePmyvCsuarZW83',
        showPassword: 'password',
        errorMessage: '',
    }
    setAuth = ()=>{
        getAuth()
        .revokeRefreshTokens(this.state.admin)
        .then(() => {
          return getAuth().getUser(this.state.admin);
        })
        .then((userRecord) => {
          return new Date(userRecord.tokensValidAfterTime).getTime() / 1000;
        })
        .then((timestamp) => {
          console.log(`Tokens revoked at: ${timestamp}`);
        });
    }
    
    signUp = async (e)  => {
        await createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then((response)=>{
            const userName = this.state.firstName + ' '+ this.state.lastName
            updateProfile(response.user, {displayName: userName})
            this.setState({redirect: true})
        }).catch((error)=>{
            const message = error.code
            this.setState({errorMessage: message})
        })
    }
    login = async (e)  => {
        await signInWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then((response)=>{
            this.setState({redirect: true})
        }).catch((error)=>{
            const message = error.code
            this.setState({errorMessage: message})
        })
    }

    handleChange(e){
        const value = e.target.value
        const name = e.target.name
        this.setState({[name]: value})
    }
    handleSubmit(e){
        e.preventDefault()
        if (this.state.accessType === 0) {
            this.login()
        }else if (this.state.accessType === 1) {
            this.signUp()
        }
    }
    handleToggle(e){
        if (this.state.showPassword === "password") {
            this.setState({showPassword:'text'})
        }else{
            this.setState({showPassword:'password'})
        }
    }
  render() {
    if (this.state.redirect) {
        if (this.props.location.pathname) {
            return <Navigate to={this.props.location.pathname}/>
        }else return <Navigate to={'/'}/>
    }
      return (
        <div className='login-wrapper | flex-center column'>
                <h3 className='mg-block-end-1'>{this.state.errorMessage}</h3>
            <form className="login | bg-primary-400 flow-8 pg-block-5 pg-inline-4 border-radius-1" onSubmit={(e) => { this.handleSubmit(e) } }>
                <h1 className="secondary-heading | mg-block-end-6 clr-accent-400 text-center">
                    {this.state.accessType===0 ? 'Login': 'Sign Up'}
                </h1>
                <ul className='flow-3'>
                    {this.state.accessType===1 &&
                    <li className='even-columns | gap-2'>
                        <input type="text" name='firstName' placeholder='First Name' onChange={(e) => { this.handleChange(e) }} required/>
                        <input type="text" name='lastName' placeholder='Last Name' onChange={(e) => { this.handleChange(e) }} required/>
                    </li>
                    }
                    <li className='flow-3'>
                        <input type="email" name='email' placeholder='Email Address' onChange={(e) => { this.handleChange(e) }} required/>
                        <input  type={this.state.showPassword} name='password' placeholder='Password' onChange={(e) => { this.handleChange(e) }} required/>
                    </li>
                    <li className='show-password flex-al-c gap-1'>
                        <label htmlFor='showPassword' className='fs-200 pointer'>Show password</label>
                        <span><input id='showPassword' type="checkbox" onClick={(e) => { this.handleToggle()}} /></span>
                    </li>
                </ul>
                <button className='button' datatype-button='primary' type='submit'>Submit</button>
                <div className='mg-block-start-2'>
                    { this.state.accessType === 0?
                        <div className='flex gap-1'>
                            <p>Don't Have and Account?</p> 
                            <span onClick={() => { this.setState({accessType: 1})} } >Sign Up</span>
                        </div>
                            :
                        <div className='flex gap-1'>
                            <p>Already Have and Account?</p> 
                            <span onClick={() => { this.setState({accessType: 0})} } >Login</span>
                        </div>                    
                    }
                </div>
            </form>
        </div>
    )
  }
}

export default Login
