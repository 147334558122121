import React, { useEffect, useState } from 'react'
import { CodeBlock, atomOneLight } from "react-code-blocks";
import JsxParser from 'react-jsx-parser';
import styled from 'styled-components';
import { HandleTab } from '../../components/Functions/HandleTab';
import SendData from '../../components/Functions/SendData';
import PopUp from '../../components/PopUp';

const StyledDiv = styled.div`${props => props.$primary}`

export default function AdminCodeContainer(props) {
    const [step, setStep] = useState(0)
    const [code, setCode] = useState(props.component.code)
    const [style, setStyle] = useState(props.component.style)
    const [undo, setUndo] = useState(false)
    const [popup, setPopup] = useState('')

    const screenWidth = props.screenWidth
    
    const test = 'button {'
    const testString = sessionStorage.getItem('button')
    
    useEffect(() => {
        if (testString) {
            if (style.search('button') !== -1) {
                let splitString = testString.split(';')
                splitString = splitString.map((x, i) => x.replace('\t', `    `) )
                const joinString = splitString.join(';')
                const newStyle= style.replace(test, test +'\n' + joinString);    
                setStyle(newStyle)
            }
        }
    }, [])

    useEffect(() => {
        if (props.component.code === code && props.component.style === style) {
            setUndo(false)
        }else{
            setUndo(true)
        }
    }, [code, style])

    window.onanimationend =()=>{
        setPopup('')
    }
    
    function setData(e) {
        const newLayout = {[props.id]:{code, style}}
        SendData('layouts', props.type, newLayout)
        setPopup('active')
    }
    function handleUndo(e) {
        setCode(props.component.code)
        setStyle(props.component.style)
    }
    function CodeContainer (e) {
        switch (step) {
            case 0:
                return (
                    <div className="code-container-wrapper | flow-3 pg-block-6 pg-inline-8">
                        <div className="code-container">
                            <CodeBlock
                                text={code}
                                language='javascript'
                                showLineNumbers={true}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                        </div>
                        <div className="code-container">
                            <CodeBlock
                                text={style}
                                language='css'
                                showLineNumbers={true}
                                theme={atomOneLight}
                            />
                        </div>
                    </div>
            )
            
            case 1:
                return (
                    <div className="code-container-wrapper | flow-3 pg-block-6 pg-inline-8">
                        <div className="code-container">
                            <pre>
                                <code>
                                    <textarea
                                        onChange={(e) => { setCode(e.target.value) }}
                                        onKeyDown={(e) => { HandleTab(e) }}
                                        value={code}
                                        className='clr-dark-400' name="" id="" />
                                </code>
                            </pre>
                        </div>
                        <div className="code-container">
                            <pre>
                                <code>
                                    <textarea
                                        onChange={(e) => { setStyle(e.target.value) }}
                                        onKeyDown={(e) => { HandleTab(e) }}
                                        value={style}
                                        className='clr-dark-400' name="" id="" />
                                </code>
                            </pre>
                        </div>
                    </div>
                )
            default:
                break;
        }
    } 
      return (
          <section className='code-container-section  flow-8'>
            <div style={{maxWidth: `${screenWidth}px`, marginInline: 'auto'}}>
                <StyledDiv $primary={style} className='component'>
                    <JsxParser jsx={code} /> 
                </StyledDiv>
            </div>
            <div className='bg-primary-400'>
            <ul className="top | flex  bg-primary-400">
                <li>
                    <button className='top_button | gap-1 flex-al-c pointer' onClick={(e) => {setStep(0)}}>
                            Show Code
                    </button>
                </li>
                <li>
                    <button className='top_button | gap-1 flex-al-c pointer' onClick={(e) => {setStep(1)}}>
                            Edit Code
                    </button>
                </li>
                <li className='flex | flex-js-e'>
                    {undo && <button className='top_button | gap-1 flex-al-c pointer' onClick={(e) => {handleUndo()}}>
                            Revert
                    </button>
                    }
                    <button className='top_button |  gap-1 flex-al-c pointer' onClick={(e) => {setData()}}>
                            Update
                    </button>
                </li>

            </ul>
            {CodeContainer()}
            <PopUp active={popup} popup={'Code Updated'} />
        </div>
    </section>
  )
}