import React, { useEffect, useState } from 'react'
import {ArrowDown, Layouts, Premium, Components, Logout, DoubleArrow, MyLayouts, Home} from '../images/icons/Icons'
import logo from '../images/logo.svg'
import {Link} from 'react-router-dom'
import {auth} from '../firebase/firebase'
import {signOut } from 'firebase/auth'

export default function Sidebar() {
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [collapse, setCollapse] = useState(false)
    const [user, setUser] = useState(null)
    const [admin, setAdmin] = useState(false)
    const [userLayouts, setUserLayouts] = useState(null)
    
    const sidebar = [
        {
            selection: 'Layouts',
            dropdown: ['Navbar', 'Cover', 'AboutUs', 'OurServices', 'Featured', 'Pricing', 'Testimonial'],
            icon: <Layouts />,
        },
        {
            selection: 'Components',
            dropdown: ['Buttons'],
            icon: <Components />,
        },
        {
            selection: 'Premium',
            dropdown:  false,
            icon: <Premium />,
        },
        {
            selection: 'My Layouts',
            dropdown: ['Navbar', 'Cover', 'AboutUs', 'OurServices', 'Featured', 'Pricing', 'Testimonial'],
            icon: <MyLayouts color='orange' className='fs-500' />,
        },
    ]
    
    function logout(e) {
        signOut(auth)
        .then((response)=>{
            console.log(response);
            window.location.reload()
        }).catch((error)=>{
            console.log(error);
        })
    }
    
    const handleClick = (e) => {
        setActiveDropdown(e) 
        //if already clicked
        if (e === activeDropdown) {
            setActiveDropdown(null)
        }
    } 
    function toggle(e) {
        if (collapse) {
            setCollapse(false)
        }else{
            setCollapse(true)
        }
    }
    function handleUserLayouts(e){
        setUserLayouts(getUserLayouts)
    }
    const getUserLayouts = JSON.parse(sessionStorage.getItem('userLayouts')) 
    useEffect(() => {
        handleUserLayouts()
    }, [sessionStorage.getItem('userLayouts')])
    
    
    useEffect(() => {
        auth.onAuthStateChanged((user)=>{
            if (user) {
                setUser(user)
                if (user.displayName === 'admin') {
                    setAdmin(true)
                }
            }
        })
    }, [user])
    
    
    
    return (
        <div className='sidebar-wrapper clr-dark-400 fw-regular' datatype={collapse ? 'collapse': ''}>
        <div className="sidebar | bg-primary-400 flex-jc-c column">
            <DoubleArrow onClick={toggle} className='collapse-icon | pointer mg-2'/>
            <a href='/' className="logo-container | text-center pg-3 ">
                <img className='mx-auto' src={logo} alt="logo" />
                <h1 className="logo | fs-400 clr-accent-400">LayoutsNblocks</h1>
            </a>
            <div className='text-center mg-block-end-2'>
                {
                    admin && <h2 className=' mx-auto clr-dark-400'>Admin</h2>
                }
            </div>
            <ul className='sidebar_buttons_wrapper'>
                {sidebar.map((x, i)=>{
                    const myLayouts = x.selection === 'My Layouts'
                    const uid = user?.uid
                    let dropdown = x.dropdown
                    let userLayout =[]
                    if (userLayouts && uid) {
                        userLayout = userLayouts.map((x)=>{
                            return(x.slice(0, -1).toLowerCase());
                        })
                    }
                    let newDropdown = []
                    if (myLayouts) {
                        dropdown.map((x)=>{
                            return userLayout?.map((e)=>{
                                if (!e.search(x.toLowerCase())) {
                                    if (newDropdown.includes(x)) {
                                    }else{
                                        return newDropdown = [...newDropdown, x];
                                    }
                                }
                            })
                        })
                        dropdown = newDropdown
                    }
                    const active = activeDropdown === i ? 'active' : 'deactive'
                    if (!collapse) {
                        return(
                            <li key={i} className='sidebar_buttons | pg-block-0'>
                                <div onClick={(e) => {dropdown && handleClick(i)}} className='flex-al-c pg-inline-3'>
                                    <span className='sidebar_icon | pg-inline-end-1'>{x.icon}</span>
                                    {
                                        dropdown ? <p className='sidebar-link fs-300'>{x.selection}</p> 
                                            : 
                                        <Link className='sidebar-link fs-300'  to={'/premium'} >Premium</Link>
                                    }
                                    <span datatype={active} className='dropdown_button'>{dropdown && <ArrowDown />}</span>
                                </div>
                                <div datatype={active} className='sub_sidebar_buttons'>
                                    {dropdown && dropdown.map((dropdown, i)=>{       
                                        const myLayouts = x.selection === 'My Layouts' ? 'mylayouts/' : ''                                 
                                        const url = admin ? '/admin' +'/'+ dropdown.toLowerCase() : '/'+ myLayouts + dropdown.toLowerCase()
                                        return(
                                            <Link to={url} className='sub_sidebar_button pg-block-1 pg-inline-start-3' key={i}><span>{dropdown}</span></Link>
                                        )                                           
                                    })}
                                </div>
                            </li>
                        )
                    }else{
                        return(
                            <li key={i} className='sidebar_buttons | pg-block-0 flex-center'>
                                <span className='sidebar_icon | pg-inline-end-1'>{x.icon}</span>
                            </li>
                        )
                    }

                })}
            </ul>
            {user ? 
            <div className='profile-wrapper'>
                {/* <Link to={'/user/' + user?.uid} className='flex-jc-c gap-1 mx-auto mg-block-end-1'>
                    <Profile className='fs-500' />
                    <p>Profile</p>
                </Link> */}
                <button onClick={(e) => { logout() }} className="logout-wrapper | pointer gap-1 flex-al-c mx-auto pg-block-end-3">
                    <Logout />
                    <p className='clr-accent-400'>Log Out</p>
                </button>
            </div>
                :
                <Link  to='/login' className="logout-wrapper | pointer gap-1 flex-al-c mx-auto pg-block-end-3">
                    <Logout />
                    <p className='clr-accent-400'>Log In</p>
                </Link>
            }
            <Link className='flex-center gap-2 mx-auto pg-block-end-8' to='/home'>
                <Home color='#00658A' className='fs-500' />
                <p className='clr-accent-400'>Home</p>
            </Link>

            
        </div>
       
    </div>
  )
}
