import {db} from '../../firebase/firebase'
import {setDoc, doc} from 'firebase/firestore'
import 'firebase/firestore'

export default function SendData(collection, ref, code, callback) {
    const docRef = doc(db, collection, ref)
    const sendData = async () => {
        await setDoc(docRef, code, {merge: true})
        .then((e) => { 
            if (typeof callback === 'function') {
                callback(e)
            }  else return;
        })
    }
    sendData()
}
