import React, { useEffect, useRef, useState } from 'react'
import { buttons } from './components/Buttons';
import { SketchPicker } from 'react-color'
import { ColorPalette } from '../images/icons/Icons';

export default function Buttons() {
  const [color, setColor] = useState('#fff')
  const [background, setBackground] = useState('#00658a')
  const [border, setBorder] = useState('#00658a')
  const [counter, setCounter] = useState(0)
  
  const [hideColor, setHideColor] = useState('hide')
  const [buttonType, setButtonType] = useState('')
  const [buttonStyleType, setButtonStyleType] = useState({})
  const [prevButton, setPrevButton] = useState()
  const [test, setTest] = useState({})
    
  let buttonStyle = {
    color: color,
    backgroundColor: background,
    border: `2px solid ${border}`,
  }

  useEffect(() => {
    setCounter(1)
    if (counter > 0) {
      onSetButtonType()
    }
  }, [color, background, border, buttonStyleType])
  

  useEffect(() => {
    const prevButtonStyle = sessionStorage.getItem('prevButton')
    if (prevButtonStyle !== JSON.stringify(buttonStyle)) {
      const removedBorder =  prevButtonStyle?.replaceAll('2px solid ', '')
      if (prevButtonStyle) {
        const prevButtons = JSON.parse(prevButtonStyle)
        delete prevButtons?.borderRadius
        delete prevButtons?.boxShadow
        setPrevButton( prevButtons)
        setColor(prevButtons.color)
        setBackground(prevButtons.backgroundColor)
        setBorder(JSON.parse(removedBorder).border )
      }else{
        setPrevButton(buttonStyle)
      }
    }
    setTest(buttonStyle)
  }, [])
    
    
    
    function onSetButtonType(e){
    if (JSON.stringify(buttonStyle)  === JSON.stringify(test)) {
      if (buttonStyleType) {
        const combinedStyles =  Object.assign(prevButton, buttonStyleType)
        let testWord = JSON.stringify(combinedStyles)
        const getUppercase = testWord?.matchAll(/[A-Z]/g)
        for (const m of getUppercase) {
          testWord = (testWord.replace(m[0], `-${m[0].toLowerCase()}`))
        }
        let newString = testWord.replaceAll('{', '\t').replaceAll('}', '').replaceAll('"', '').replaceAll(',', ';').replaceAll('(0; 0; 0; 0.4)','(0, 0, 0, 0.4)').replaceAll(';', ';\n\t').replaceAll(':', ': ')
        sessionStorage.setItem('button', newString + ';')
        sessionStorage.setItem('prevButton', JSON.stringify(combinedStyles))
      }
    }else{
      const combinedStyles =  Object.assign(buttonStyle, buttonStyleType)  
      let testWord = JSON.stringify(combinedStyles)
      const getUppercase = testWord?.matchAll(/[A-Z]/g)
      for (const m of getUppercase) {
        testWord = (testWord.replace(m[0], `-${m[0].toLowerCase()}`))
      }
      let newString = testWord.replaceAll('{', '\t').replaceAll('}', '').replaceAll('"', '').replaceAll(',', ';').replaceAll('(0; 0; 0; 0.4)','(0, 0, 0, 0.4)').replaceAll(';', ';\n\t').replaceAll(':', ': ')
      setPrevButton(buttonStyle)
      sessionStorage.setItem('button', newString + ';')
      sessionStorage.setItem('prevButton', JSON.stringify(buttonStyle))
    }
  } 
  
  function getColor(e) {
    if (buttonType === 'text') {
      return color
    }
    if (buttonType === 'background') {
      return background
    }
    if (buttonType === 'border') {
      return border
    }
  }
  function onColorChange(e){
    if (buttonType === 'text') {
      setColor(e.hex)
    }
    if (buttonType === 'background') {
      setBackground(e.hex)
    }
    if (buttonType === 'border') {
      setBorder(e.hex )
    }
  } 
  function toggleColor(e) {
    if (e === 'pickerParent') {
      setHideColor('hide')
    }else {
      setButtonType(e)
      if (hideColor === 'hide') {
        setHideColor('show')
      }else{
        if (e === buttonType) {
          setButtonType('')
          setHideColor('hide')
        }
      }
    }
  }
  const handleButtonClick =  (e) => {
    onSetButtonType()
    setButtonStyleType(e)
  }
  const handlePickerClick =  (e) => {
    if (e === 'pickerParent') {
      setHideColor('hide')
      setButtonType('')
    }
  }

  return (
    <div className='buttons-component | flow-5 '>
      <div className='buttons-component_filter relative'> 
        <div className='responsive-even-columns | gap-2'>
          <button datatype-buttonactive={buttonType === 'text' ? 'true' : ''} 
            className='color-picker-button | flex-al-c gap-2 pg-block-2 bg-accent-800' 
            onClick={(e) => { toggleColor('text') }}>
            <ColorPalette  />
            Text
          </button>
          <button datatype-buttonactive={buttonType === 'background' ? 'true' : ''} 
            className='color-picker-button | flex-al-c gap-2 pg-block-2 bg-accent-800' 
            onClick={(e) => { toggleColor('background') }}>
            <ColorPalette  />
            Background
          </button>
          <button datatype-buttonactive={buttonType === 'border' ? 'true' : ''} 
            className='color-picker-button | flex-al-c gap-2 pg-block-2 bg-accent-800' 
            onClick={(e) => { toggleColor('border') }}>
            <ColorPalette  />
            Border
          </button>
        </div>
      </div>
          <div onClick={(e) => { handlePickerClick(e.target.id) }} id='pickerParent' datatype-hide={hideColor} className=' clr-dark-400'>
            <div id='picker' className='inline-block' >
              <SketchPicker className='picker' onChange={(e) => { onColorChange(e) }} onChangeComplete={(e) => { onColorChange(e) }} color={getColor()}/>
            </div>
          </div>
      <div className="divider | mg-block-start-8"></div>
      <div className='buttons-container | responsive-even-columns gap-3'>
        {
          buttons.map((x, key)=>{
            delete prevButton?.boxShadow
            return (
                <button 
                  key={key} onClick={(e) => { handleButtonClick({[x.type]: x.value}) }} 
                  className='pointer'
                  style={{...prevButton,[x.type]: x.value}}>
                  Click Me
                </button>
            )
          })
        }
      </div>
    </div>
  )
}

