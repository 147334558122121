import React, { useEffect, useState } from 'react'
import Logo from '../images/logo.svg'
import { Link, useLocation } from 'react-router-dom'
import { Facebook, Instagram, LinkedIn, RightArrow } from '../images/icons/Icons'
import Checkout from './Checkout'
import Auth from '../components/Functions/Auth'

export default function Premium(props) {
  let location = useLocation()
  const [email, setEmail] = useState(null)
  useEffect(() => {
      Auth((e) => { setEmail(e?.email) })
      props.setLocation(location)
  }, [])
  return (
    <main className='premium text-center'>
            <header className='header | container flex-jc-sb flex-al-c'>
        <a href='/home' className="logo-wrapper">
          <img src={Logo} alt="" className="logo" />
        </a>
        <ul className="navLinks flex gap-2">
          <li className='flex-al-c'>
            <Link to='/' datatype-button='primary' className='button button-img'>
              Get Layouts
              <RightArrow color='white'/>
            </Link>
          </li>
        </ul>
      </header>
        <section className='hero-wrapper | container column flex-center gap-5'>
            <h1 className='hero-heading | clr-accent-400'>Premium Coming Soon</h1>
            <p>Get access to <span className='clr-accent-400 fw-bold'>ALL</span> of our layouts and our special features.</p>
            <Checkout email={email}  />
        </section>
        <footer className='section bg-dark-400 clr-primary-800'>
        <div className='container even-columns gap-2'>
          <div className="left-col">
            <img src={Logo} alt="" className="logo mg-block-end-6" />
            <div className='flow-2'>
              <h3>Follow Us</h3>
              <ul className='social-links | flex gap-1'>
                <li><Instagram/></li>
                <li><LinkedIn/></li>
                <li><Facebook/></li>
              </ul>
            </div>
          </div>
          <div className="right-col flow-3">
            <h3>Email Us</h3>
            <textarea className='fs-200' name="" id=""></textarea>
            <button datatype-button='primary' className='button'>Submit</button>
          </div>
        </div>
      </footer>
    </main>
  )
}
