import {db} from '../../firebase/firebase'
import {collection, getDocs, onSnapshot} from 'firebase/firestore'
import 'firebase/firestore'


export default function GetData(collections, callBack) {
        const docRef = collection(db, collections)
        let data = []
        const getData = async ()=>{
                const docSnap = await getDocs(docRef);
                onSnapshot(docRef, (snap) => {
                                if (!snap) {
                                        return getServerData()
                                }else{
                                        snap.forEach((section) => {
                                                const newData = {[section.id]:section.data()}
                                                if (!data?.includes(newData)) {
                                                        data.push(newData)
                                                }
                                        })
                                        callBack(data)
                                }
                })
                function getServerData() {
                        docSnap.forEach((doc)=>{
                                data.push({[doc.id]:doc.data()})
                        })
                        callBack(data);     
                }
                }
        getData()
    
}
