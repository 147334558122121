import React, { useEffect, useRef, useState } from 'react'
import Layouts from './layouts/Layouts'
import {Link, useParams, useNavigate, redirect} from 'react-router-dom'
import { Computer, MyLayouts, Phone, Tablet } from '../images/icons/Icons'
import Buttons from './Buttons'
import GetData from './Functions/GetData'
import Auth from './Functions/Auth'
import AddLayout from '../screens/Admin/AddLayout'
import { onAuthStateChanged } from 'firebase/auth'
import Axios from 'axios'

export default function Main() {
  const [screenWidth, setScreenWidth] = useState(1440)
  const [phoneWidth, setPhoneWidth] = useState(393)
  const [tabletWidth, setTabletWidth] = useState(768)
  const [computerWidth, setComputerWidth] = useState(1440)
  const [count, setCount] = useState(0)
  const [auth, setAuth] = useState(null)
  const [userLayouts, setUserLayouts] = useState(null)
  const [layouts, setLayouts] = useState(null)
  const [admin, setAdmin] = useState(false)
  let param = useParams()
  let navigate = useNavigate()
  const ref = useRef(null)

  function GetParam(e) {
    const pathname = window.location.pathname
    let layoutLength = null
    if (layouts && param.block && param.block !== 'buttons') {
      layoutLength = Object.values(layouts?.find((x)=>x[param?.block])[param?.block])
    }
    if (pathname.includes('add-layout')) {
      return <AddLayout layoutType={param.block} layoutLength={layoutLength?.length}/>
    }else{
      if (param.block === 'buttons') {
        return <Buttons />
      }else return <Layouts layouts={layouts} screenWidth={screenWidth}/>
    }   
  }
  function handleOnclick(e) {
    setScreenWidth(e)
  }
  function handleGetUsers(e){
    if (auth && userLayouts) {
      const uid = auth.uid
      const testLayouts = Object.values(userLayouts)
      let userLayout = testLayouts?.map((x)=>{
        return x[uid]?.layouts
      })
      userLayout = userLayout[0]
      if (userLayout) sessionStorage.setItem('userLayouts', JSON.stringify(userLayout))
    }else{
      sessionStorage.removeItem('userLayouts')
    }
  }
  useEffect(() => {
    handleGetUsers()
  }, [auth, userLayouts, layouts])
  
  useEffect(() => {
    if (window.location.pathname === '/cancel') {
      navigate('/premium')
    }
    if (count===0) {
      Auth(setAuth)
      GetData('layouts', setLayouts)
      GetData('users', setUserLayouts)
    }
    setCount(1)
}, [])
useEffect(() => {
  if (auth) {
    if (auth.displayName === 'admin') {
      setAdmin(true)
    }
  }
}, [auth])
  
  function setDefaultButton() {
    if (!sessionStorage.getItem('button')) {
      const defaultButton = `    color:#fff;
      background-color:#00658a;
      border:2px solid #00658a;
      border-radius:50vw;`
      sessionStorage.setItem('button', defaultButton)
    }
  }
  setDefaultButton()
  return (
    <main className='main-wrapper | pg-block-8 '>
      <div className='top-wrapper | container mx-auto flex-jc-c column'>
        <input type="text" 
          className="search-bar | bg-primary-400 clr-dark-400 |  pg-block-3 pg-inline-7 border-circle mg-block-end-3" 
          placeholder='Search'
        />
      </div>
      {
        param !== 'buttons' &&    
        <div className='buttons-wrapper | flex-jc-c gap-2 mg-block-end-5 pg-inline-2 pg-block-1 border-radius-5 | bg-primary-400 '>
            <button onClick={(e) => { handleOnclick(phoneWidth) } }><Phone /></button>
            <button onClick={(e) => { handleOnclick(tabletWidth) } }><Tablet /></button>
            <button onClick={(e) => { handleOnclick(computerWidth) } }><Computer /></button>
        </div>
      }
      {
        auth && param.block && !param.component && admin &&
        <Link to='add-layout' relative="path" className='button flex-jc-c mg-block-end-5'>Add Layout</Link>
      }


      <div className="main | container" >
        <section className='layout-wrapper' ref={ref} >
          <GetParam />
        </section>
      </div>
    </main>
  )
}
