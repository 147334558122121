import React, { useEffect, useState } from 'react'
import {useParams, useNavigate, useHref, Link} from 'react-router-dom'
import CodeContainer from '../CodeContainer'
import AdminCodeContainer from '../../screens/Admin/AdminCodeContainer'
import JsxParser from 'react-jsx-parser'
import styled from 'styled-components';
import Loading from '../Loading'
import Auth from '../Functions/Auth'
import NewLayout from './NewLayout'
import { Unlock } from '../../images/icons/Icons'

const StyledDiv = styled.div`${props => props.$primary}`;

export default function Navbar(props) {
  const [redirect, setRedirect] = useState(false)
  const [layouts, setLayouts] = useState(props.layouts)
  const [userLayouts, setUserLayouts] = useState(null)
  const [auth, setAuth] = useState(null)
  const [fillerLayout, setFillerLayout] = useState(null)
  const [count, setCount] = useState(0)

  const navigate = useNavigate();
  let params = useParams()
  let href = useHref()
  const block = params.block
  const component = params.component
  const admin = href.includes('admin')
  const myLayouts = href.includes('mylayouts')
  const screenWidth = props.screenWidth
  //navigate
  useEffect(() => {
    Auth(setAuth)
    if (count === 0) {
      const sessionUserLayouts = sessionStorage.getItem('userLayouts')
      if (sessionUserLayouts) setUserLayouts(JSON.parse(sessionUserLayouts))
    }
    if (redirect) {
      navigate(`${redirect}`)
    }
    setCount(1)
  }, [navigate, redirect])

  function GetParam(e) {
    // const uid = auth.uid
    const userLayout = userLayouts
    return layouts?.map((layout, layoutKey)=>{
      if (layout[block]) {
        return  Object.entries(layout[block]).sort().map(([keys, value], i) => {
          if (!userLayout?.includes(keys) && myLayouts) {
          }else{
          let x = {[keys]: value};
          const code = Object.values(x)[0].code
          let style = Object.values(x)[0].style
          const key =  Object.keys(x)
          const type =  Object.keys(layout)[0]
          const id =  Object.keys(x)[0]
          const test = 'button {'
          const testString = sessionStorage.getItem('button')
          if (testString) {
            if (style.search('button') !== -1) {
                  const newStyle= style.replace(test, test + '\n' + testString);    
                  style = newStyle
              }
          }
        if (x[component]) {
          if (admin) {
          return <AdminCodeContainer favorite={userLayout} screenWidth={screenWidth} type={type} id={id} key={i} component={x[component]} /> 
        }else{
          return <CodeContainer favorite={userLayout} screenWidth={screenWidth} type={type} id={id} key={i} component={x[component]} /> 
        }
      }
      if (block && !component) {
        if (layouts?.length === 0) {
          return <Loading />
        }else{
          if (i=== 0  ) {
            if (!fillerLayout) {
              setFillerLayout({code:code, style:style})
            }
          }
          return(   
            <div style={{maxWidth: `${screenWidth}px`}}>
              <div className=''>
                <StyledDiv onClick={(e) => { setRedirect(key) }} $primary={style} key={i} className='component'>
                    <JsxParser key={i} jsx={code} />    
                </StyledDiv>
              </div>
            </div>
          )
        }
      }
    }
    })}
  })
  }
  return (
    <>
      {!layouts ? <Loading/>: 
      <div className={`${block}-layout-wrapper layouts-wrapper | flow-8`}>
        <GetParam/>
        {fillerLayout && !myLayouts &&
        <div style={{maxWidth: `${screenWidth}px`}}>
              <div className='filler-layout-wrapper'>
                <Link to='/premium'  className='dark-overlay | flex-center gap-2'>
                  <Unlock />
                  <h2>Unlock Premium to access more layouts</h2>
                </Link>
                <StyledDiv $primary={fillerLayout.style} className='component'>
                    <JsxParser jsx={fillerLayout.code} />    
                </StyledDiv>
              </div>
            </div>
        }
        {/* <NewLayout /> */}
        </div>
      }
    </>
  )
}
