import { useState } from 'react';
import AdminScreen from './screens/Admin/AdminScreen';
import Checkout from './screens/Checkout';
import HomePage from './screens/HomePage';
import HomeScreen from './screens/HomeScreen';
import Login from './screens/Login';
import Premium from './screens/Premium';
import './styles/Main.css';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import Success from './screens/Success';

function App() {
  const [location, setLocation] = useState('hello')
  return (
    <div className="App">
      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <Routes>
          {/* HomePage */}
          <Route path='/home' Component={HomePage}/>
          {/* homescreen */}
          <Route path='/'>
            <Route index Component={HomeScreen}/>
            <Route path=':block' Component={HomeScreen}/>
            <Route path=':block/:component' Component={HomeScreen}/>
            <Route path='/mylayouts/:block' Component={HomeScreen}/>
            <Route path='/mylayouts/:block/:component' Component={HomeScreen}/>
            {/* Paymen t */}
            <Route path='/success' Component={Success}/>
            <Route path='/cancel' Component={HomeScreen}/>
          </Route>
         {/* admin */}
         <Route path='/admin'>
          <Route index Component={AdminScreen}/>
          <Route path=':block' Component={AdminScreen}/>
          <Route path=':block/:component' Component={AdminScreen}/>
         </Route>
          {/* login */}
          <Route path='/login' loader={'hello'} element={<Login location={location}/>}/>
          {/* Premium */}
          <Route path='/premium' element={<Premium setLocation={setLocation}/>}/>
          <Route path='/premium/checkout' Component={Checkout}/>
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
