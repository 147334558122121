import React, { useState } from 'react'
import SendData from '../../components/Functions/SendData'

export default function AddLayout(props) {
    const [code, setCode] = useState()
    const [style, setStyle] = useState()
    function handleSendData(){
        const layoutLength = props.layoutLength +1
        const layoutType = props.layoutType
        const objName = layoutType + layoutLength
        const objval = {code:code, style:style}
        const newObj ={[objName]:objval} 
        SendData('layouts',props.layoutType, newObj)
        
    }
  return (
    <div className='add-layout-wrapper relative flow-4'>
        <div className="html-wrapper">
            <h1>Add Html</h1>
            <textarea onChange={(e) => { setCode(e.target.value) }} name="" id="code"></textarea>
        </div>
        <div className="css-wrapper">
            <h1>Add Css</h1>
            <textarea onChange={(e) => { setStyle((e.target.value)) }} name="" id="style"></textarea>
        </div>
        {

        }
        <button className='button' datatype-button='secondary' onClick={handleSendData}>Send Data</button>
        
    </div>
  )
}
